import React from "react"
import { Flex, Box } from "rebass"
import Figure from "../components/Figure"
import { H1, H3, P, Ul } from "../components/VerticalRhythm"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Section from "../components/Section"
import RespGrid from "../components/RespGrid"
import breakpoints from "../theme/breakpoints"

import PictureSet from "../components/PictureSet"
import imgObj18 from "../imgObjs/imgObj18"

const DataProtectionPage = () => (
  <Layout>
    <SEO title="Datenschutz" />
    <Figure id={"master"} caption="">
      <PictureSet imgObj={imgObj18} />
    </Figure>
    <Section>
      <H1 mb={3}>Datenschutz</H1>
      <P>
        Geesche Martin
        <br />
        Butzbacher Str. 25
        <br />
        60325 Frankfurt
        <br />
        kontakt@hebamme-in-frankfurt.de
      </P>
      <P mb={1}>Wir verarbeiten</P>
      <Ul mb={0}>
        <li>
          Vertragsdaten (z.B., Vertragsgegenstand, Laufzeit, Kundenkategorie).
        </li>
        <li>Zahlungsdaten (z.B., Bankverbindung, Zahlungshistorie)</li>
      </Ul>
      <P>
        von unseren Kunden, Interessenten und Geschäftspartner zwecks Erbringung
        vertraglicher Leistungen, Service und Kundenpflege, Marketing, Werbung
        und Marktforschung.
      </P>
      <P>
        Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer
        berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO Daten
        über jeden Zugriff auf den Server, auf dem sich dieser Dienst befindet
        (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören Name der
        abgerufenen Webseite, Datei, Datum und Uhrzeit des Abrufs, übertragene
        Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp nebst Version,
        das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite),
        IP-Adresse und der anfragende Provider.
      </P>
      <P>
        Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur Aufklärung
        von Missbrauchs- oder Betrugshandlungen) für die Dauer von maximal 7
        Tagen gespeichert und danach gelöscht. Daten, deren weitere Aufbewahrung
        zu Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des
        jeweiligen Vorfalls von der Löschung ausgenommen.
      </P>
      <P>
        Diese Seite trackt für sich keine Nutzerdaten und setzt auch keine
        Analyse-Tools, wie Google-Analytics ein. Jedoch wird diese Seite auf{" "}
        <a
          href="https://www.netlify.com/"
          rel="nofollow"
          rel="noreferrer"
          target="_blank"
        >
          https://www.netlify.com/
        </a>{" "}
        gehostet. Details zum Datenschutz von Netlify entnehmen Sie bitte{" "}
        <a
          href="https://www.netlify.com/gdpr/"
          rel="nofollow"
          rel="noreferrer"
          target="_blank"
        >
          https://www.netlify.com/gdpr/
        </a>
        . Vielen Dank.
      </P>

      <P>
        <em>
          Erstellt mit Datenschutz-Generator.de von RA Dr. jur. Thomas Schwenke
        </em>
      </P>
    </Section>
  </Layout>
)

export default DataProtectionPage
